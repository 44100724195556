body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alignText{
  text-align: center;
  margin: auto;
}

.alignBlock{
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.Table
{
    display: table;
    width: 40%;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0.9em;
}

.Row
{
    display: table-row;
    text-align: center;
}

.Cell
{
    display: table-cell;
    width: 100%;
}

.textArea {
  resize: none;
  white-space: nowrap;
}